@use "src/styles/layers";

.watermark {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: layers.$watermark;
  opacity: .5;
  background-color: white;
  padding: .5rem 1rem;
  border-radius: 2rem;
}