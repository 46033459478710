:root {
  --border-radius: 5px;
  --black: #000;
  --white: #fff;
  --matte-black: #4d4d4d;
  --success: #00b050;

  --primary-base: #ff0018;
  --primary-contrast: #fff;
  --primary-light: #ffb3bb;
  --primary-negative: #666666;

  --secondary-base: #0c65d5;
  --secondary-base-hover: #09438c;
  --secondary-contrast: #fff;
  --secondary-light: #348eff;
  --secondary-negative: #666666;

  --error-base: #ff0018;
  --error-contrast: #fff;
  --error-light: #ffe6e8;
  --error-dark: #d70015;

  --grey-light: #f7f7f7;
  --grey-base: #e6e6e6;
  --grey-dark: #b3b3b3;
  --grey-darker: #333333;
  --grey-border: #d9d9d9;
  --grey-support: #4d4d4d;
  --grey-support-light: #666666;

  --warning-yellow: #ffb84d;
  --warning-yellow-hover: #de962b;
  --warning-yellow-light: #fff1db;

  //750 theme global colors
  --750-red: hsla(5, 100%, 50%, 1);
  --750-blue: hsla(237, 97%, 50%, 1);
  --750-purple: hsla(285, 100%, 44%, 1);
  --750-mint: hsla(157, 78%, 56%, 1);

  // * Additional colors used in gradients * //
  --750-fresh-green: hsla(83, 98%, 66%, 1); /* #9BFE60 */
  --750-vivid-blue: hsla(240, 100%, 49%, 1); /* #0000FA */

  --750-deep-blue: hsla(236, 100%, 48%, 1); /* #0709EC */
  --750-bright-red: hsla(357, 89%, 50%, 1); /* #E62129 */

  --750-vibrant-red: hsla(357, 100%, 50%, 1); /* #E8091F */
  --750-sunny-orange: hsla(31, 98%, 60%, 1); /* #FA9D18 */

  --750-warm-orange: hsla(31, 99%, 60%, 1); /* #FB9C18 */
  --750-light-green: hsla(76, 73%, 69%, 1); /* #C8EF52 */

  // * Gradients * //
  --750-gradient-01: linear-gradient(
    -30deg,
    var(--750-fresh-green),
    var(--750-vivid-blue)
  );

  --750-gradient-02: linear-gradient(
    -30deg,
    var(--750-bright-red),
    var(--750-deep-blue)
  );

  --750-gradient-03: linear-gradient(
    -30deg,
    var(--750-vibrant-red),
    var(--750-sunny-orange)
  );

  --750-gradient-04: linear-gradient(
    -30deg,
    var(--750-warm-orange),
    var(--750-light-green)
  );

  --750-gradient-05: linear-gradient(
    -30deg,
    var(--750-light-green),
    var(--750-sunny-orange)
  );

  // * extended gradients to use in buttons with 200% background-size * //
  --750-gradient-01-extended: linear-gradient(
    -30deg,
    var(--750-vivid-blue),
    var(--750-fresh-green),
    var(--750-vivid-blue)
  );

  --750-gradient-02-extended: linear-gradient(
    -30deg,
    var(--750-bright-red),
    var(--750-deep-blue),
    var(--750-bright-red)
  );

  --750-gradient-03-extended: linear-gradient(
    -30deg,
    var(--750-sunny-orange),
    var(--750-vibrant-red),
    var(--750-sunny-orange)
  );

  --750-gradient-04-extended: linear-gradient(
    -30deg,
    var(--750-light-green),
    var(--750-warm-orange),
    var(--750-light-green)
  );

  // * Reversed gradients * //
  --750-gradient-01-reversed: linear-gradient(
    -30deg,
    var(--750-vivid-blue),
    var(--750-fresh-green)
  );

  --750-gradient-02-reversed: linear-gradient(
    -30deg,
    var(--750-bright-red),
    var(--750-deep-blue)
  );

  --750-gradient-03-reversed: linear-gradient(
    -30deg,
    var(--750-sunny-orange),
    var(--750-vibrant-red)
  );

  --750-gradient-04-reversed: linear-gradient(
    -30deg,
    var(--750-light-green),
    var(--750-warm-orange)
  );

  --750-gradient-05-reversed: linear-gradient(
    -30deg,
    var(--750-sunny-orange),
    var(--750-light-green)
  );
}
