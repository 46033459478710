@use "typography";
@use "palette";
@use "themes";
@use "breakpoints";

@include typography.set-font-face("AmsterdamSans", 300, normal, "AmsterdamSans-Light");
@include typography.set-font-face("AmsterdamSans", 400, normal, "AmsterdamSans-Regular");
@include typography.set-font-face("AmsterdamSans", 400, italic, "AmsterdamSans-Italic");
@include typography.set-font-face("AmsterdamSans", 700, normal, "AmsterdamSans-Bold");
@include typography.set-font-face("AmsterdamSans", 700, italic, "AmsterdamSans-BoldItalic");
@include typography.set-font-face("AmsterdamSans", 800, normal, "AmsterdamSans-ExtraBold");

$breakpoints: (
  "xs": breakpoints.$breakpoint-xs,
  "sm": breakpoints.$breakpoint-sm,
  "md": breakpoints.$breakpoint-md,
  "lg": breakpoints.$breakpoint-lg,
  "xl": breakpoints.$breakpoint-xl
);

html,
body {
  padding: 0;
  margin: 0;
  font-family: sans-serif;
  line-height: 1.2;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;

  &:after,
  &:before {
    box-sizing: inherit;
  }
}

body {
  font-family: "AmsterdamSans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html:has(.modal.open),
html:has(.modal.open) body {
  overflow: hidden;
}

a {
  color: palette.brand("black", "base");
}

.grecaptcha-badge {
  visibility: hidden;
}

.sr_only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

// Visibility utility classes
@each $breakpoint, $value in $breakpoints {
  .show_upto_#{$breakpoint} {
    @media (min-width: #{$value}px) {
      display: none !important;
    }
  }

  .show_from_#{$breakpoint} {
    @media (max-width: #{$value - 1}px) {
      display: none !important;
    }
  }

  .hide_upto_#{$breakpoint} {
    @media (max-width: #{$value - 1}px) {
      display: none !important;
    }
  }

  .hide_from_#{$breakpoint} {
    @media (min-width: #{$value}px) {
      display: none !important;
    }
  }
}

@each $start_name, $start_value in $breakpoints {
  @each $end_name, $end_value in $breakpoints {
    @if $end_value > $start_value {
      .show_#{$start_name}_to_#{$end_name} {
        @media (max-width: #{$start_value - 1}px) {
          display: none !important;
        }
        @media (min-width: #{$end_value}px) {
          display: none !important;
        }
      }

      .hide_#{$start_name}_to_#{$end_name} {
        @media (min-width: #{$start_value}px) and (max-width: #{$end_value - 1}px) {
          display: none !important;
        }
      }
    }
  }
}

[data-theme="750"] {
  h1 > *,
  h1,
  h2 > *,
  h2 {
    text-transform: uppercase;
  }
}
